import { ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary'
import { track } from '../../logic';
import { MfaEnrollmentPrompt } from '../MfaEnrollmentPrompt';
import { ErrorDialog } from './ErrorDialog';

const onError = (error: Error, info: {componentStack: string}) => {
  track('error', 'error boundary', {}, {
    message: error.message,
    error: JSON.stringify(error),
    component_stack: info?.componentStack,
  })
}

// IMPROVE: this could show something behind the dialog, maybe a picture of Todd?
function ErrorFallback({error, resetErrorBoundary}: {
  error: any;
  resetErrorBoundary:  React.MouseEventHandler<HTMLButtonElement>;
}) {

  // Try to get the orderKey from the url.  Since we're higher up in an error
  // boundary we we can't use useParams().  Better idea might be to have a
  // <OrderRoot> component that has the boundary and wraps all the sub-routes.
  let orderKey = ''
  const match = window.location.pathname.match(/\/closings\/(.*?)(\/|$)/)
  if (match) {
    orderKey = match[1]
  }

  if (error.type === 'mfa-required' && orderKey) {
    const onSuccess = () => window.location.reload()
    const { mfaRedacted, SmsPreferenceStatus } = error.mfaObject

    return (
      <MfaEnrollmentPrompt
        onSuccess={onSuccess}
        redactedPhoneNumber={mfaRedacted}
        orderKey={orderKey}
        SmsPreferenceStatus={SmsPreferenceStatus}
      />
    )
  } else {
    return (
      <ErrorDialog error={error} errorBoundaryHandler={resetErrorBoundary} />
    )
  }
}

export const ErrorBoundary = ({ children }: {
	children: JSX.Element;
}) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={onError}
      onReset={() => {
        track('try_again', 'error boundary')
        // IMPROVE: anything clever to do here to help? At some point, reloadiing
        // might be baaaad.
        window.location.reload()
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}
