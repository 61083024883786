import { Outlet, useLocation, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GetOrderResponse } from '../types';
import { track, useGetOrder } from '../logic';
import { SmsPreferenceStatusPrompt } from './SmsPreferenceStatusPrompt';
import { useOrganization } from './OrganizationProvider';

type ContextType = { globalOrderData: GetOrderResponse | null, globalOrderDataIsLoading: boolean, setGlobalOrderData: Function, }

export function useOrderDataContext() {
  return useOutletContext<ContextType>();
}


function OrderDataProviderWithClosingId({ closingId}: {
  closingId: string;
}) {
  let { search } = useLocation()
  const { data, loading, error } = useGetOrder(closingId, search)
  const [globalOrderData, setGlobalOrderData] = useState<GetOrderResponse | null>(data);
  const [globalOrderDataIsLoading, setGlobalOrderDataIsLoading] = useState<boolean | null>(loading);
  const organization = useOrganization()

  let [searchParams, setSearchParams] = useSearchParams();

  let { Role } = globalOrderData || {}

  // Add/change role param if there is none or if the current query param doesn't match.
  useEffect(() => {
    const currentRoleParam = searchParams.get('role')

    if (Role && Role !== currentRoleParam) {
      searchParams.set('role', Role)
      setSearchParams(searchParams)
    }
  }, [Role, searchParams, setSearchParams])

  useEffect(() => {
    setGlobalOrderData(data)
  }, [data])

  useEffect(() => {
    setGlobalOrderDataIsLoading(loading)
  }, [loading])

  if (error) {

    // TODO: is there a better way to avoid these error TS errors
    // @ts-ignore
    if (error.type !== 'mfa-required') {
      track('error', 'useGetOrder', {}, {
        message: 'unable to find this closing',
        component: 'ClosingPage',
        error: JSON.stringify(error),
      })
    }

    // @ts-ignore
    if (!error?.userErrorMessage && !error?.message) {
      // @ts-ignore
      error.userErrorMessage = 'We were unable to load this closing'
    }

    // NOTE: mfa-required error.type needs to throw for mfa to work properly
    throw error
  }

  const commPrefs = data?.Order.SmsPreferenceStatus
  const needsOptIn = organization.SmsOptInRequired && commPrefs?.phoneNumber && commPrefs?.consent == null

  return (
    <>
      {data && needsOptIn ?
        <SmsPreferenceStatusPrompt orderKey={closingId} SmsPreferenceStatus={commPrefs}/> :
        <></>
      }
      <Outlet context={{ globalOrderData, globalOrderDataIsLoading, setGlobalOrderData }}/>
    </>
  )
}

function OrderDataProviderWithNoDataOrAnything() {
  return (
    <>
      <Outlet context={{}}/>
    </>
  )
}

export function OrderDataProvider() {
  const { closingId = '' } = useParams()

  return closingId ? (
    <OrderDataProviderWithClosingId closingId={closingId} />
  ) : (
    <OrderDataProviderWithNoDataOrAnything />
  )
}
