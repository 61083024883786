import { Form } from '@formio/react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { track, useContent, useFormSubmit, useGetNotificationStatuses, useUserCancelTask, useUserCompleteTask, useInAppSneezeGuard, useBrowserSneezeGuard, useUserResetTask, useGetIdCheckUrl } from '../../logic'
import { GetOrderResponse, PortalNotification, PortalOrder, PortalSigner } from '../../types'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';

import "bootstrap/dist/css/bootstrap.css";
import "formiojs/dist/formio.full.min.css";
import { Box } from '@mui/system'
import { useLocation } from 'react-router-dom'
import { set } from 'lodash'
import { ErrorDialog } from './ErrorDialog'
import { HelpButton } from './Help'
import { LoadingSigningStatuses } from './LoadingSignatureIframe'
import { useOrderDataContext } from '../OrderDataProvider'
import { PortalSignature } from './PortalSignature'
import { NotificationPagination } from './NotificationPagination'
import { toTitleCase } from '../../utils'
import { LoadingButton } from '@mui/lab'
import { UploadsListDialog } from './UploadsListDialog'
import { useFileUpload } from './useFileUpload'
import { usePageVisibility } from './usePageVisibility'
import { usePrevious } from './usePrevious'
import { useNotificationsDialog } from './usePollNotifications'


const reOpenEsigningMessage = 'View your e-signing'
const notificationStatusesPollingDelay = 2 * 1000;
const defaultNotificationStatusesPollMax = 8
const multiSignerNotificationStatusesPollMax = 4


// IMPROVE: pull all updating of globalOrderData into a hook
export function PortalTaskComponent({ form, order, notification, notificationIndex, refreshNotification, loadingNotification }: {
  form?: any;
  order: PortalOrder;
  notification: PortalNotification;
  notificationIndex: number;
  refreshNotification: Function
  loadingNotification: boolean
}) {
  const { globalOrderData, setGlobalOrderData } = useOrderDataContext()
  const { search } = useLocation()
  const { TaskText, TasksText, WiringInstructionsText } = useContent()
  const orderKey = order.CompanyIdOrderIdHash
  const formKey = form?.FormId
  const isSigningTask = notification.Type === 'SigningTask'
  const hasSigning = form?.HasSignature || isSigningTask
  const hasDataSubmissionStep = notification.HasDataSubmissionStep
  const isUploadTask = notification.Type === 'UploadTask'
  const isIdCheckTask = notification.Type === 'IdCheckTask'
  const isWiringInstructionsTask = notification.Type === 'WiringInstructionsTask'
  const WiringInstructionsTaskCompleteButtonText = globalOrderData?.Order?.WiringInstructions?.TaskCompleteButtonText;

  const [uploadTaskDialogOpen, setUploadTaskDialogOpen] = useState(false);
  const { uploadInput, fileUploadBody, fileUploadActions, resetFileUploadHook, isComplete, files } = useFileUpload({ notificationId: notification.NotificationId })


  const [formData, setFormData] = useState({})
  const [initialFormDataJson, setInitialFormDataJson] = useState('')
  const [latestFormDataJson, setLatestFormDataJson] = useState('')
  const [sneezeGuardShouldPrompt, setSneezeGuardShouldPrompt] = useState(false)

  const [additionalSigners, setAdditionalSigners] = useState([] as PortalSigner[])
  const [notificationStatusesPollMax, setNotificationStatusesPollMax] = useState(defaultNotificationStatusesPollMax);

  // Don't auto open signing for e-sign anything signings so we can show the description
  const [signatureOpen, setSignatureOpen] = useState(!isSigningTask);

  const [userCancelOpen, setUserCancelOpen] = useState(false);
  const [userResetOpen, setUserResetOpen] = useState(false);
  const [userCompleteOpen, setUserCompleteOpen] = useState(false);
  const [formSubmitErrorOpen, setFormSubmitErrorOpen] = useState(false);
  const [downloadUploadsOpen, setDownloadUploadsOpen] = useState(false);

  const [idCheckUrl, setIdCheckUrl] = useState('')

  // IMPROVE: clean this up / move to parent component. Then reference notification
  // object directly and get rid of local state. Maybe add periodic polling
  // for statuses when page is active.
  const [statuses, setStatuses] = useState({
    ReadReceipt: notification.ReadReceipt,
    IsFormComplete: notification.IsFormComplete,
    IsSigningCancelled: notification.IsSigningCancelled,
    IsSigningComplete: notification.IsSigningComplete,
    IsCancelled: notification.IsCancelled,
    IsComplete: notification.IsComplete,
  })

  const { refresh: refreshNotificationStatuses, data: notificationStatusesResponse, loading: isLoadingNotificationStatuses, error: notificationStatusesError, resetState: resetNotificationStatusesState } = useGetNotificationStatuses(orderKey, notification.NotificationId, search)
  const [lastPoll, setLastPoll] = useState(0)
  const [pollCount, setPollCount] = useState(0)
  const [isPolling, setIsPolling] = useState(false)

  const { refresh: refreshFormSubmit, error: formSubmitError, data: userFormSubmitSuccess } = useFormSubmit(orderKey, formKey, formData, notification.NotificationId, search)
  const { refresh: refreshUserCancel, loading: userCancelLoading, error: userCancelError, data: userCancelSuccess } = useUserCancelTask(orderKey, notification.NotificationId, search)
  const { refresh: refreshUserReset, loading: userResetLoading, error: userResetError, data: userResetSuccess } = useUserResetTask(orderKey, notification.NotificationId, search)
  const { refresh: refreshUserComplete, loading: userCompleteLoading, error: userCompleteError, data: userCompleteSuccess } = useUserCompleteTask(orderKey, notification.NotificationId, search)
  const { refresh: refreshGetIdCheckUrl, loading: getIdCheckUrlLoading, error: getIdCheckUrlError, data: getIdCheckUrlSuccess, resetState: resetGetIdCheckUrl } = useGetIdCheckUrl(orderKey, notification.NotificationId, search)

  const [userCompletedSigning, setUserCompletedSigning] = useState(false)
  const [openedIdCheckUrl, setOpenedIdCheckUrl] = useState(false)

  const isVisible = usePageVisibility();
  const prevIsVisible = usePrevious(isVisible);

  const { pollNotificationsDialog, handleOpen: setPollNotificationsDialogOpen } = useNotificationsDialog()

  const handleUploadClick = (e: any) => {
    if (isComplete) {
      resetFileUploadHook()
    }

    setUploadTaskDialogOpen(true)
  }

  const handleIdCheckClick = (e: any) => {
    resetPolling()
    refreshGetIdCheckUrl()
  }

  const onUploadTaskDialogClose = (e: any) => {
    e.preventDefault()
    setUploadTaskDialogOpen(false)
    setUserCompleteOpen(true)
  }

  const maybeSetPollNotificationsDialogOpen = () => {
    if (hasDataSubmissionStep) {
      setPollNotificationsDialogOpen()
    }
  }

  const prompt = 'You will lose changes to your form. Are you sure you wish to leave?'
  useInAppSneezeGuard(prompt, sneezeGuardShouldPrompt)
  useBrowserSneezeGuard(sneezeGuardShouldPrompt)

  // Task canceled or user manually canceled
  useEffect(() => {
    if (userCancelSuccess) {
      setStatuses(prev => {
        return {
          ...prev,
          IsCancelled: true,
          IsComplete: true,
        }
      })

      setGlobalOrderData((prev: GetOrderResponse) => {
        const globalOrderData = { ...prev }
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsCancelled`, true)
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsComplete`, true)
        return globalOrderData
      })

      setUserCompletedSigning(false)
    }
  }, [userCancelSuccess, setGlobalOrderData, notificationIndex])

  // Task manually reset
  useEffect(() => {
    if (userResetSuccess) {
      setStatuses(prev => {
        return {
          ...prev,
          IsFormComplete: false,
          IsSigningCancelled: false,
          IsSigningComplete: false,
          IsCancelled: false,
          IsComplete: false,
        }
      })

      setGlobalOrderData((prev: GetOrderResponse) => {
        const globalOrderData = { ...prev }
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsFormComplete`, false)
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsSigningCancelled`, false)
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsSigningComplete`, false)
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsCancelled`, false)
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsComplete`, false)
        return globalOrderData
      })

      setUserCompletedSigning(false)
    }
  }, [userResetSuccess, setGlobalOrderData, notificationIndex])


  // User manually completed
  useEffect(() => {
    if (userCompleteSuccess) {
      maybeSetPollNotificationsDialogOpen()

      setStatuses(prev => {
        return {
          ...prev,
          IsComplete: true,
        }
      })

      setGlobalOrderData((prev: GetOrderResponse) => {
        const globalOrderData = { ...prev }
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsComplete`, true)
        return globalOrderData
      })

    }
    // TODO: try adding maybeSetPollNotificationsDialogOpen to the deps and wrapping in useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCompleteSuccess, setGlobalOrderData, notificationIndex])

  useEffect(() => {
    if (getIdCheckUrlSuccess && !isComplete && !openedIdCheckUrl) {
      // add idverse url to not warn about leaving the portal?
      // TODO: check on pop up blockers?
      window.open(getIdCheckUrlSuccess.url, '_blank')

      setIdCheckUrl(getIdCheckUrlSuccess.url ?? '')

      setOpenedIdCheckUrl(true)
      resetGetIdCheckUrl({loading: false})
    } else if (!isComplete && openedIdCheckUrl && !prevIsVisible && isVisible) {
      // TODO: think about polling without blocking the rest of the ui
      // would we like this in general? if not always, we could break up this component
      setIsPolling(true)
      setOpenedIdCheckUrl(false)
    }
  }, [getIdCheckUrlSuccess, resetGetIdCheckUrl, openedIdCheckUrl, isComplete, isVisible, prevIsVisible])

  // update state from polling for events
  useEffect(() => {
    if (notificationStatusesResponse) {
      setStatuses(prev => {
        return {
          ...prev,
          ...notificationStatusesResponse
        }
      })

      setGlobalOrderData((prev: GetOrderResponse) => {
        const globalOrderData = { ...prev }
        set(globalOrderData, `Notifications[${notificationIndex}].IsFormComplete`, notificationStatusesResponse.IsFormComplete)
        set(globalOrderData, `Notifications[${notificationIndex}].IsSigningComplete`, notificationStatusesResponse.IsSigningComplete)
        set(globalOrderData, `Notifications[${notificationIndex}].IsComplete`, notificationStatusesResponse.IsComplete)
        set(globalOrderData, `Notifications[${notificationIndex}].IsCancelled`, notificationStatusesResponse.IsCancelled)
        set(globalOrderData, `Notifications[${notificationIndex}].IsSigningCancelled`, notificationStatusesResponse.IsSigningCancelled)
        return globalOrderData
      })
    }
  }, [notificationStatusesResponse, setGlobalOrderData, notificationIndex])

  // User successfully submitted form
  useEffect(() => {
    if (userFormSubmitSuccess) {
      const isComplete = !hasSigning

      if (isComplete) {
        maybeSetPollNotificationsDialogOpen()
      } else {
        setSignatureOpen(true)
      }

      setStatuses(prev => {
        return {
          ...prev,
          IsFormComplete: true,
          IsComplete: isComplete,
        }
      })

      setGlobalOrderData((prev: GetOrderResponse) => {
        const globalOrderData = { ...prev }
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsComplete`, isComplete )
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsFormComplete`, true)
        return globalOrderData
      })
    }

    // TODO: try adding maybeSetPollNotificationsDialogOpen to the deps and wrapping in useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFormSubmitSuccess, notificationIndex, setGlobalOrderData, hasSigning])


  // Toggle form submit error dialog
  useEffect(() => {
    setFormSubmitErrorOpen(!!formSubmitError)
  }, [formSubmitError])


  // form data changed. update sneeze guard
  useEffect(() => {
    if (!initialFormDataJson) {
      setInitialFormDataJson(latestFormDataJson)
    } else if ((latestFormDataJson !== initialFormDataJson) && !statuses.IsFormComplete && !statuses.IsComplete) {
      setSneezeGuardShouldPrompt(true)
    } else {
      setSneezeGuardShouldPrompt(false)
    }
  }, [initialFormDataJson, latestFormDataJson, statuses])

  function updateAdditionalSigners(data: any) {
    let unauthedSigners: PortalSigner[] = []

    for (const [key, val] of Object.entries(data)) {
      if (key.startsWith('signerNames')) {
        const moreSigners = (val as any).filter((signer:PortalSigner) => signer.email)
        unauthedSigners = [...unauthedSigners, ...moreSigners]
      }
    }

    if (unauthedSigners.length) {
      setNotificationStatusesPollMax(multiSignerNotificationStatusesPollMax)
    }

    setAdditionalSigners(unauthedSigners)
  }

  // form submitted
  const onSubmit = (event: any, form: any) => {
    const payload = {
      ...event,
      notificationId: notification.NotificationId,
    }

    // payload requires these. We may want to add them in differently at some point.
    const title = form?.DisplayName || notification.Title || 'Form'
    payload.data = {
      // TODO: real description?
      description: title,
      title,
      ...payload.data
    }

    updateAdditionalSigners(payload.data)
    setFormData(payload)
    refreshFormSubmit()
  }


  function onUserCancelTask() {
    setIsPolling(false)
    refreshUserCancel()
    setUserCancelOpen(false)
  }

  function onUserResetTask() {
    setIsPolling(false)
    refreshUserReset()
    setUserResetOpen(false)
  }

  function onUserCompleteTask() {
    setIsPolling(false)
    refreshUserComplete()
    setUserCompleteOpen(false)
  }

  // cleanup polling on unmount
  useEffect(() => {
    return () => setIsPolling(false)
  }, [])

  useEffect(() => {
    if (!hasSigning && !isIdCheckTask) {
      resetPolling()

      // Look for signing specific events instead of only isComplete, otherwise task can
      // look complete depending on the timing of creating the formComplete event
      // and the needsSigning event. This will allow polling to continue longer and
      // all events to be created
    } else if (hasSigning && (notificationStatusesResponse?.IsSigningComplete || notificationStatusesResponse?.IsSigningCancelled)) {
      resetPolling()
      maybeSetPollNotificationsDialogOpen()
      // this would only apply if they submitted the form and then came back later to complete the signing
    } else if (isIdCheckTask && notificationStatusesResponse?.IsComplete) {
      resetPolling()
      maybeSetPollNotificationsDialogOpen()
    } else if (isPolling && !isLoadingNotificationStatuses) {
      pollNotificationStatuses()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingNotificationStatuses, notificationStatusesResponse, isPolling]);


  // attempt to poll for notificationStatuses event
  function pollNotificationStatuses() {
    const now = Date.now()
    const elapsed = now - lastPoll
    const remainingDelay = notificationStatusesPollingDelay - elapsed
    const pollingMaxReached = pollCount >= notificationStatusesPollMax

    if (!isPolling) {
      return
    } else if (pollingMaxReached) {
      resetPolling()
    } else {
      setTimeout(() => {
        refreshNotificationStatuses()
        setLastPoll(Date.now())
        setPollCount((prevCount) => prevCount + 1)
      }, Math.max(remainingDelay, 0))
    }
  }

  function onSigningClose() {
    const isSingleSigner = userFormSubmitSuccess && additionalSigners?.length === 0
    const isMultiSigner = userFormSubmitSuccess && additionalSigners?.length > 0

    if (isSingleSigner && userCompletedSigning) {
      maybeSetPollNotificationsDialogOpen()

      setStatuses(prev => {
        return {
          ...prev,
          IsComplete: true,
        }
      })

      setGlobalOrderData((prev: GetOrderResponse) => {
        const globalOrderData = { ...prev }
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsComplete`, true)
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsFormComplete`, true)
        set(globalOrderData || {}, `Notifications[${notificationIndex}].IsSigningComplete`, true)
        return globalOrderData
      })

    } else if (isMultiSigner && userCompletedSigning) {
      maybeSetPollNotificationsDialogOpen()
    } else {
      setIsPolling(true)
    }
  }

  function resetPolling() {
    setIsPolling(false)
    setPollCount(0)
    setLastPoll(0)
    resetNotificationStatusesState({ loading: false })
  }

  // error handling
  if (formSubmitError || userCompleteError || userCancelError || notificationStatusesError || userResetError || getIdCheckUrlError) {
    let err
    let label

    if (formSubmitError) {
      err = formSubmitError
      label = 'useFormSubmit'
    } else if (userCompleteError) {
      err = userCompleteError
      label = 'useUserCompleteTask'
    } else if (userCancelError) {
      err = userCancelError
      label = 'useUserCancelTask'
    } else if (notificationStatusesError) {
      err = notificationStatusesError
      label = 'useGetNotificationStatuses'
    } else if (userResetError) {
      err = userResetError
      label = 'useUserResetTask'
    } else if (getIdCheckUrlError) {
      err = getIdCheckUrlError
      label = 'useGetIdCheckUrl'
    }

    // log all to trackjs
    track('error', label, {}, {
      message: JSON.stringify(err),
      component: 'PortalTaskComponent',
    })

    // Alert errors from user actions, not polling errors. formSubmitError handled
    // separately to help avoid losing form data.
    if (userCompleteError || userCancelError || userResetError || getIdCheckUrlError) {
      return <ErrorDialog error='There was an error processing your request.' tryAgainText='Reload and try again' />
    }
  }

  function getAdditionalSignersText() {
    const multiple = additionalSigners.length > 1
    return `${multiple ? 'Emails have' : 'An email has'} been sent to the following ${multiple ? 'people' : 'person'} to facilitate their e-signing${multiple ? 's' : ''}:`
  }


  const showForm = form && !isPolling && !statuses.IsFormComplete && !statuses.IsComplete
  const showCompleteMessage = !isPolling && statuses.IsComplete && !statuses.IsCancelled
  const showCancelledMessage = !isPolling && statuses.IsComplete && statuses.IsCancelled
  const showLinkToDownloadSigningDocs = !isPolling && (hasSigning) && !statuses.IsCancelled && statuses.IsComplete && statuses.IsSigningComplete
  const showLinkToDownloadForm = !isPolling && !statuses.IsCancelled && statuses.IsComplete && statuses.IsFormComplete && ((!hasSigning) || (form?.HasSignature && statuses.IsSigningCancelled))
  const showLinkToDownloadUploadTaskDocs = isUploadTask && !isPolling && !statuses.IsCancelled && statuses.IsComplete
  const showSigningIncompleteDetails = !isPolling && !statuses.IsComplete && ((statuses.IsFormComplete && form?.HasSignature) || isSigningTask)
  const showSigningDialog = !statuses.IsComplete && ((statuses.IsFormComplete && form?.HasSignature && !statuses.IsSigningComplete) || (isSigningTask && !statuses.IsSigningComplete))
  const showNotificationPagination = statuses.IsFormComplete || statuses.IsComplete

  const showReopenYourSigningButton = isPolling && hasSigning
  // const showRefreshStatusButton = isIdCheckTask && openedIdCheckUrl && !isComplete && !isPolling
  const showUploadButton = isUploadTask && !statuses.IsComplete
  const showIdCheckButton = isIdCheckTask && !statuses.IsComplete && !isPolling

  // Hiding user complete for now.
  const showUserCompleteButton = false && isUploadTask && !statuses.IsComplete
  const showUserCancelButton = !isIdCheckTask && !isSigningTask && !statuses.IsFormComplete && !statuses.IsComplete
  const showUserResetButton = !isIdCheckTask && !isSigningTask && !isPolling && (statuses.IsComplete || statuses.IsFormComplete || statuses.IsCancelled)



  return (
    <>
      {/* polling for notificationStatuses */}
      {isPolling && <>
        <LoadingSigningStatuses />
      </>}

      {showReopenYourSigningButton && <>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="outlined"
            sx={{
              minWidth: "250px",
              alignSelf: "center",
              mt: 4,
              borderRadius: "40px",
            }}
            onClick={() => {
              resetPolling();
              setSignatureOpen(true);
            }}
          >
            {reOpenEsigningMessage}
          </Button>
        </Box>
      </>}

      {/* Show the form */}
      {showForm ? (
        <Box sx={{ mb: 4 }}>
          <Form
            onChange={({ data }: { data: object }) =>
              setLatestFormDataJson(JSON.stringify(data))
            }
            form={form?.Config}
            options={{ noAlerts: true }}
            onSubmit={(event: any) => onSubmit(event, form)}
          />
        </Box>
      ) : (
        ""
      )}

      {/* Task is complete message */}
      {showCompleteMessage ? (
        <Alert severity="success" sx={{ mb: 3 }}>
          Thank you for completing your {TaskText}!
        </Alert>
      ) : (
        ""
      )}

      {/* Task is canceled message */}
      {showCancelledMessage ? (
        <Alert icon={<CancelOutlinedIcon />} severity="error" sx={{ mb: 3 }}>
          This {TaskText} has been canceled
        </Alert>
      ) : (
        ""
      )}


      {/* Open dialog for Tasks that might have uploads associated with them for downloading :) */}
      {showLinkToDownloadForm || showLinkToDownloadSigningDocs || showLinkToDownloadUploadTaskDocs ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "40px",
              minWidth: "250px",
              color: "white",
            }}
            onClick={() => {
              refreshNotification()
              setDownloadUploadsOpen(true)
            }}
          >
            {isWiringInstructionsTask ?
              (WiringInstructionsTaskCompleteButtonText || `Download ${toTitleCase(WiringInstructionsText)}`) :
              `Download ${toTitleCase(TaskText)} Documents`
            }
          </Button>
        </Box>
      ) : (
        ""
      )}

      {/* Form is complete, signing incomplete */}
      {showSigningIncompleteDetails ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {additionalSigners.length ? (
            <>

              {userCompletedSigning ?
                <>
                  <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
                    You have completed your part of this e-signing!
                  </Typography>
                  <Typography variant="body2">
                    Once the parties listed below have completed e-signing, the signed document
                    will be available for you to download here, a copy will be
                    sent to us, and this {TaskText} will be completed.
                  </Typography>
                </> :
                <>
                  <Typography variant="body2" sx={{ mb: 2, fontWeight: 'bold' }}>
                    If you haven't completed your e-signing, please click "
                    {reOpenEsigningMessage}" below.
                  </Typography>
                  <Typography variant="body2">
                    Once you and the parties listed below have completed
                    e-signing, the signed document will be available for you to
                    download here, a copy will be sent to us, and this {TaskText} will
                    be completed.
                  </Typography>
                </>
              }

              <Typography sx={{ fontWeight: "bold", mt: 2 }}>
                {getAdditionalSignersText()}
              </Typography>
              <List>
                {additionalSigners.map((signer, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      sx={{ wordBreak: "break-word" }}
                      primaryTypographyProps={{ fontSize: "default" }}
                      primary={`${signer.firstName} ${
                        signer.middleName || ""
                      } ${signer.lastName}`}
                      secondary={signer.email}
                      secondaryTypographyProps={{
                        variant: "light",
                        fontSize: "small",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <>
              {userCompletedSigning ? (
                <>
                  <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
                    You have completed your part of this e-signing!
                  </Typography>
                  <Typography variant="body2">
                    Once all parties have completed their e-signing, the signed document
                    will be available for you to download here, a copy will be
                    sent to us, and this {TaskText} will be completed.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2" sx={{ mb: 2, fontWeight: 'bold' }}>
                    If you haven't completed your e-signing, please click "
                    {reOpenEsigningMessage}" below.
                  </Typography>
                  <Typography variant="body2">
                    Once all parties have completed their e-signing, the signed document
                    will be available for you to download here, a copy will be
                    sent to us, and this {TaskText} will be completed.
                  </Typography>
                </>
              )}
            </>
          )}

          <Button
            variant={isSigningTask ? "contained" : "outlined"}
            sx={{
              minWidth: "250px",
              alignSelf: "center",
              mt: 4,
              borderRadius: "40px",
            }}
            onClick={() => {
              resetPolling();
              setSignatureOpen(true);
            }}
          >
            {reOpenEsigningMessage}
          </Button>
        </Box>
      ) : (
        ""
      )}

      {/* Form is complete, Show signing dialog */}
      {showSigningDialog ? (
        <PortalSignature
          form={form}
          order={order}
          notification={notification}
          open={signatureOpen}
          setOpen={setSignatureOpen}
          onSigningClose={onSigningClose}
          setUserCompletedSigning={setUserCompletedSigning}
          userCompletedSigning={userCompletedSigning}
        />
      ) : (
        ""
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 1,
        }}
      >

        {/* User complete task */}
        {showUploadButton && (
          <LoadingButton
            loading={userCompleteLoading}
            variant="contained"
            sx={{
              borderRadius: "40px",
              minWidth: "250px",
            }}
            onClick={handleUploadClick}
          >
            Upload documents{uploadInput}
          </LoadingButton>
        )}


        {/* Show Id check */}
        {showIdCheckButton && (
          <>
            <LoadingButton
              loading={getIdCheckUrlLoading}
              variant="contained"
              sx={{
                borderRadius: "40px",
                minWidth: "250px",
              }}
              onClick={handleIdCheckClick}
            >
              Verify your identity
            </LoadingButton>

            {/*
                if they opened the url, came back and polling didn't show that
                they completed the task, give them a button to poll status again
             */}
            {/* {showRefreshStatusButton && (
              <LoadingButton
                loading={isPolling}
                variant="outlined"
                sx={{
                  mt: 1,
                  borderRadius: "40px",
                  minWidth: "250px",
                }}
                onClick={() => {
                  resetPolling()
                  setIsPolling(true)
                }}
                startIcon={<Refresh />}
              >
                Refresh {TaskText} status
              </LoadingButton>
            )} */}
          </>
        )}

        {/* Show Id check link for blocked popups */}
        {idCheckUrl && (
          <Box sx={{ mt: 2 }}>
            If a new window did not open you can allow pop-ups or&nbsp;
            <a href={idCheckUrl} target='_blank' rel='noreferrer'>
              click here to verify your identity in a new window.
            </a>
          </Box>
        )}


        {/* User complete task */}
        {showUserCompleteButton && (
          <LoadingButton
            loading={userCompleteLoading}
            variant="text"
            sx={{
              borderRadius: "40px",
              minWidth: "250px",
              mt: 1,
            }}
            onClick={() => setUserCompleteOpen(true)}
          >
            Complete {TaskText}
          </LoadingButton>
        )}


        {/* User cancel task */}
        {showUserCancelButton && (
          <LoadingButton
            loading={userCancelLoading}
            variant="text"
            sx={{
              borderRadius: "40px",
              minWidth: "250px",
              color: "primary.main",
              mt: 1,
            }}
            onClick={() => setUserCancelOpen(true)}
          >
            Cancel this {TaskText}
          </LoadingButton>
        )}


        {/* User reset task */}
        {showUserResetButton && (
          <LoadingButton
            loading={userResetLoading}
            variant="text"
            sx={{
              borderRadius: "40px",
              minWidth: "250px",
              color: "primary.main",
            }}
            onClick={() => setUserResetOpen(true)}
          >
            Restart this {TaskText}
          </LoadingButton>
        )}
      </Box>


      {/* Additional navigation. Next task, Next Message, etc. */}
      {showNotificationPagination ? (
        <NotificationPagination
          isTasks={true}
          currentNotificationId={notification.NotificationId}
        />
      ) : (
        ""
      )}

      {/* User manual action confirmation dialogs */}
      <Dialog key="userComplete" open={userCompleteOpen}>
        <DialogTitle>Complete {toTitleCase(TaskText)}</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>Are you sure you want to mark this {TaskText} as completed?</DialogContentText>
          <DialogContentText>If needed, completed {TasksText} can be restarted.</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="rounded" onClick={() => setUserCompleteOpen(false)}>
            Not yet
          </Button>
          <Button variant="contained" onClick={onUserCompleteTask}>
            Yes, complete it
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog key="userCancel" open={userCancelOpen}>
        <DialogTitle>Cancel {toTitleCase(TaskText)}</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>
            Are you sure you want to cancel this {TaskText}?
          </DialogContentText>
          {isSigningTask && (
            <DialogContentText>
              After canceling, the {TaskText} will not be available to complete for your transaction.
            </DialogContentText>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => setUserCancelOpen(false)}>
            No, keep it
          </Button>
          <Button variant="rounded" onClick={onUserCancelTask}>
            Yes, cancel it
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog key="userReset" open={userResetOpen}>
        <DialogTitle>Restart {toTitleCase(TaskText)}</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>
            Are you sure you want to restart this {TaskText}?
          </DialogContentText>
          <DialogContentText>
            Restarting will allow you to complete this {TaskText} from the
            beginning.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => setUserResetOpen(false)}>
            No
          </Button>
          <Button variant="rounded" onClick={onUserResetTask}>
            Yes, restart it
          </Button>
        </DialogActions>
      </Dialog>

      {/* Form submission error dialog, to allow for user retries without losing data */}
      <Dialog
        key="formSubmitError" open={formSubmitErrorOpen}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ml: -1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              mr: 4,
            }}
          >
            <ErrorOutlineIcon fontSize="large" color="error" sx={{ mr: 1 }} />
            <Typography
              sx={{
                fontWeight: "500",
                lineHeight: "18px",
              }}
            >
              Sorry, something went wrong
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={() => {
              setFormSubmitErrorOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There was an error submitting your form.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <HelpButton />
          <Button
            onClick={() => {
              refreshFormSubmit();
              setFormSubmitErrorOpen(false);
            }}
          >
            Resubmit your form
          </Button>
        </DialogActions>
      </Dialog>

      {/* Download task uploads dialog */}
      <UploadsListDialog
        open={downloadUploadsOpen}
        setOpen={setDownloadUploadsOpen}
        uploads={notification.Uploads || []}
        refreshList={refreshNotification}
        loadingList={loadingNotification}
        notification={notification}
      />

      {/* Dialog for upload task */}
      <Dialog
        open={uploadTaskDialogOpen}
        onClose={onUploadTaskDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
            minHeight: "350px",
            m: { xs: 0, sm: '32px' },
            width: { xs: '100%', sm: 'calc(100% - 64px)' },
          },
        }}
        fullWidth={true}
      >
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "300", fontSize: "32px" }}
        >
          Upload
        </DialogTitle>

        <DialogContent>{fileUploadBody}</DialogContent>

        <DialogActions sx={{ mb: 1 }}>
          {fileUploadActions}

          {((!!files.length && isComplete) || !files.length)  &&
            <Button variant={(!files.length) ? 'rounded' : 'contained'} sx={{ ml: 0 }} onClick={onUploadTaskDialogClose}>
              I'm done
            </Button>
          }
        </DialogActions>
      </Dialog>


      {pollNotificationsDialog}
    </>
  );
}
