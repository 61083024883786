/**
 * Returns the current page's url, looking for an override query param too via:
// http://localhost:3000/closings?pageUrlOverride=http://abcportal.dev.portal.closesimple.com/foo
 */
export function getPageUrl() {
  const pageUrlOverride = new URLSearchParams(window.location.search).get('pageUrlOverride')
  const pageUrl = window.location.toString()
  const isDev = ['localhost:3000', 'dev.portal.closesimple.com'].includes(window.location.host)
  if (isDev && pageUrlOverride) {
    console.warn('using pageUrl override:', pageUrlOverride)
    return pageUrlOverride
  }
  return pageUrl
}