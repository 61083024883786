import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { track, useGetNeedsSigning } from '../../logic'
import { ESignProvider, PortalNotification, PortalOrder } from '../../types'
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useLocation } from 'react-router-dom'
import HelloSign from 'hellosign-embedded';
import { LoadingSignatureIframe } from './LoadingSignatureIframe'
import { SignatureHelp } from './SignatureHelp';

const needsSigningPollingDelay = 2 * 1000;
const needsSigningPollMax = 20

export const needsSigningErrors = {
  signingCompleted: 'signingCompleted'
}


const DropboxSigning = ({ setIsSigningIframeLoading, signingUrl, onSigningDialogClose, setUserCompletedSigning, setShowErrorLoadingSigning, containerRef }: {
  setIsSigningIframeLoading: Function;
  signingUrl: string;
  onSigningDialogClose: Function;
  setUserCompletedSigning: Function;
  setShowErrorLoadingSigning: Function;
  containerRef: React.RefObject<HTMLElement>;
}) => {
  const client = new HelloSign({
    clientId: process.env.REACT_APP_DROPBOXSIGN_CLIENT_ID || '',
  });

  // const theme = useTheme() as any
  // const primaryColor = theme.palette.primary.main


  useEffect(() => {
    // OPTIONS for client.open
    // export interface Options {
    //   allowCancel?: boolean | undefined;
    //   clientId?: string | undefined;
    //   container?: HTMLElement | undefined;
    //   debug?: boolean | undefined;
    //   hideHeader?: boolean | undefined;
    //   locale?: Locales[keyof Locales] | undefined;
    //   redirectTo?: string | undefined;
    //   requestingEmail?: string | undefined;
    //   skipDomainVerification?: boolean | undefined;
    //   testMode?: boolean | undefined;
    //   timeout?: number | undefined;
    //   whiteLabeling?: object | undefined;
    // }

    // whiteLabelingOptions: {
    //   "header_background_color": "#00adbb",
    //   "primary_button_color": "#43454d",
    //   "primary_button_text_color": "#ffffff",
    //   "primary_button_color_hover": "#43454d",
    //   "primary_button_text_color_hover": "#ffffff",
    //   "link_color": "#00adbb",
    //   "page_background_color": "#f4f7f9",
    //   "secondary_button_color": "#43454d",
    //   "secondary_button_text_color": "#ffffff",
    //   "legal_version": "terms2"
    // },

    client.open(signingUrl, {
      // Add this for running in localhost
      skipDomainVerification: true,
      allowCancel: true,
      container: containerRef.current ?? undefined,
      hideHeader: true,

      // TODO:  signings are all emitting a server error when using this. Figure out why
      // (unpaid plan maybe??). low priority so punting on this for now.
      //whiteLabeling: {
      //  header_background_color: "#00FF00",
      // primary_button_color: "#00FF00",
      //},
    });

    client.once('ready', (data: any) => {
      console.log(`dropbox - "ready" - Data: ${JSON.stringify(data)}`);
      setIsSigningIframeLoading(false)
    });

    client.once('sign', (data: any) => {
      console.log(`dropbox - "sign" - Data: ${JSON.stringify(data)}`);
      setUserCompletedSigning(true)
    });

    client.once('finish', () => {
      console.log('dropbox - "finish"');
      onSigningDialogClose()
    });

    client.once('cancel', () => {
      console.log('dropbox - "cancel"');
      onSigningDialogClose()
    });

    client.on('error', (data: any) => {
      track('error', 'dropbox-signing', {}, {
        message: JSON.stringify(data),
        component: 'PortalSignature',
      })

      console.log(`dropbox - "error" - Data: ${JSON.stringify(data)}`);
      setShowErrorLoadingSigning(true)
    });

    return () => {
      client.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export function ESignProviders({ isSigningIframeLoading, setIsSigningIframeLoading, eSignProvider, signingUrl, onSigningDialogClose, setUserCompletedSigning, setShowErrorLoadingSigning }: {
  isSigningIframeLoading: boolean;
  setIsSigningIframeLoading: Function;
  eSignProvider?: ESignProvider;
  signingUrl: string;
  onSigningDialogClose: Function;
  setUserCompletedSigning: Function;
  setShowErrorLoadingSigning: Function
}) {
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (signingUrl) {
      setIsSigningIframeLoading(true)
    }
  }, [signingUrl, setIsSigningIframeLoading])


  return (
    <Box
      ref={containerRef}
      sx={{
        display: (isSigningIframeLoading) ? 'none' : 'inherit',
        width: '100%',
        height: '100%',
        '.header': { display: 'none' },
      }}
    >
      <DropboxSigning
        containerRef={containerRef}
        setIsSigningIframeLoading={setIsSigningIframeLoading}
        signingUrl={signingUrl}
        onSigningDialogClose={onSigningDialogClose}
        setUserCompletedSigning={setUserCompletedSigning}
        setShowErrorLoadingSigning={setShowErrorLoadingSigning}
      />
    </Box>
  )

}


export function PortalSignature({ form, order, notification, open, setOpen, onSigningClose, setUserCompletedSigning, userCompletedSigning }: {
  form?: any;
  order: PortalOrder;
  notification: PortalNotification;
  open: boolean;
  setOpen: Function;
  onSigningClose: Function;
  setUserCompletedSigning: Function;
  userCompletedSigning: boolean;
}) {
  const theme = useTheme() as any
  const primaryColor = theme.palette.primary.main
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { search } = useLocation()
  const orderKey = order.CompanyIdOrderIdHash
  const [isSigningIframeLoading, setIsSigningIframeLoading] = useState(false);
  const { refresh: refreshNeedsSigning, data: needsSigningResponse, loading: loadingNeedsSigning, resetState: resetNeedsSigningState } = useGetNeedsSigning(orderKey, notification.NotificationId, search)
  const [lastPoll, setLastPoll] = useState(0)
  const [pollCount, setPollCount] = useState(0)
  const [isPolling, setIsPolling] = useState(true)
  const [showHelp, setShowHelp] = useState(false)
  const [showErrorLoadingSigning, setShowErrorLoadingSigning] = useState(false)

  const isDropboxSigning = needsSigningResponse?.ESignProvider === 'DropboxSign'
  const signingCompletedErrorResponse = needsSigningResponse?.errorMessage === needsSigningErrors.signingCompleted
  // TODO: handle unsupported eSignProvider error message like above? tag: #enotary

  // If on open we're not polling, reset state and start polling
  // This is mostly for closing and reopening a dropbox signing url, which can
  // only be opened once
  useEffect(() => {
    if (open && !isPolling) {
      resetComponentState()
      setIsPolling(true)
    } else if (!open) {
      setIsPolling(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])


  // Polling for a signing URL. This runs every time isPolling or loadingNeedsSigning changes.
  useEffect(() => {
    const now = Date.now()
    const elapsed = now - lastPoll
    const remainingDelay = needsSigningPollingDelay - elapsed
    const pollingMaxReached = pollCount >= needsSigningPollMax

    // A URL was returned or we've reached the arbitrary max polling count. Stop polling
    if (needsSigningResponse?.url || pollingMaxReached) {
      setIsPolling(false)

    // This signing was already completed. Stop polling
    } else if (signingCompletedErrorResponse) {
      setIsPolling(false)
      setIsSigningIframeLoading(false)
      setUserCompletedSigning(true)

    // Poll again
    } else if (isPolling && !loadingNeedsSigning) {
      setTimeout(() => {
        refreshNeedsSigning()
        setLastPoll(Date.now())
        setPollCount((prevCount) => prevCount + 1)
      }, Math.max(remainingDelay, 0));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingNeedsSigning, isPolling]);


  function resetPolling() {
    setIsPolling(false)
    setLastPoll(0)
    setPollCount(0)
  }

  function onSigningDialogClose() {
    onSigningClose()
    setOpen(false)
    resetPolling()
  }

  function resetComponentState() {
    resetPolling()
    setIsSigningIframeLoading(false)
    resetNeedsSigningState({ loading: false })
    setShowErrorLoadingSigning(false)
    setUserCompletedSigning(false)
  }

  const showHavingTroubleButton = !isDropboxSigning && needsSigningResponse?.url && !isSigningIframeLoading
  const showNoSigningUrlFoundError = showErrorLoadingSigning || (!isPolling && !needsSigningResponse?.url && !loadingNeedsSigning && !isSigningIframeLoading && !signingCompletedErrorResponse)
  const showLoadingSignatureIframe = !userCompletedSigning && !showNoSigningUrlFoundError && (isSigningIframeLoading || loadingNeedsSigning || isPolling)
  const startLoadingIframe = !userCompletedSigning && needsSigningResponse?.url
  const showCloseButton = userCompletedSigning
  const showCompletedMessage = userCompletedSigning

  return (
    <>
      <Dialog
        open={open}
        fullScreen={isSmall ? true : false}
        disableEscapeKeyDown={true}
        sx={{
          height: '100%',
          padding: { xs: '8px' },
          '& .MuiDialog-paper': { borderRadius: '20px', height: '100%' }
        }}
        fullWidth={true}
        maxWidth='xl'
      >
        <DialogTitle sx={{
          'borderBottom': `1px solid ${primaryColor}`,
          display: 'flex', alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          {notification.Title || form?.DisplayName || 'E-signing'}
          <IconButton
            aria-label="close"
            onClick={onSigningDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {/* show loading if we're polling/loading the iframe URL or if we have URL and the iframe hasn't loaded yet */}
          {showLoadingSignatureIframe && <LoadingSignatureIframe />}

          {/* show error and allow user to restart polling if polling failed to get the iframe URL */}
          {showNoSigningUrlFoundError ?
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
              <Alert icon={<CancelOutlinedIcon />} severity="error" sx={{ mb: 2, fontSize: 'medium' }}>
                Sorry, there was an error loading your e-signing. Please try again.
              </Alert>
              <Button
                variant="contained"
                sx={{
                  minWidth: '250px',
                  alignSelf: 'center',
                  mt: 4,
                }}
                onClick={() => {
                  resetComponentState()
                  setIsPolling(true)
                }}
              >
                Try again
              </Button>
            </Box> :
            ''
          }

          {/* render iframe once we have the URL. don't display it until the iframe finishes loading */}
          {startLoadingIframe &&
            <ESignProviders
              isSigningIframeLoading={isSigningIframeLoading}
              setIsSigningIframeLoading={setIsSigningIframeLoading}
              eSignProvider={needsSigningResponse.ESignProvider}
              signingUrl={needsSigningResponse.url || ''}
              onSigningDialogClose={onSigningDialogClose}
              setUserCompletedSigning={setUserCompletedSigning}
              setShowErrorLoadingSigning={setShowErrorLoadingSigning}
            />
          }

          {showCompletedMessage &&
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3, mb: 3 }}>
              <Alert severity="success" sx={{ fontSize: 'medium' }}>
                You have completed e-signing this document!
              </Alert>
            </Box>
          }

        </DialogContent>
        <DialogActions
          sx={{
            alignItems: 'center',
            mb: 1, mr: 1,
          }}
        >
          {showHavingTroubleButton && <Button
            onClick={() => setShowHelp(!showHelp)}
            sx={{
              display: 'flex',
              minWidth: '100px',
            }}
          >Having trouble?</Button>}

          {showCloseButton && <Button
            onClick={onSigningDialogClose}
            variant="contained"
            sx={{
              display: 'flex',
              minWidth: '100px',
            }}
          >Close</Button>}
        </DialogActions>
      </Dialog>


      {/* HELP DIALOG */}
      <SignatureHelp open={showHelp} setOpen={setShowHelp} isSmall={isSmall} />
    </>
  )
}
