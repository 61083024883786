import { compact, partition } from "lodash";
import { PortalNotificationReference, PortalNotificationType } from "../types";

export const taskTypes: PortalNotificationType[] = [
  'SigningTask',
  'WiringInstructionsTask',
  'UploadTask',
  'FormTask',
  'IdCheckTask',
]

function sortByDate(a: string, b: string) {
  return (Date.parse(b) || 0) - (Date.parse(a) || 0)
}

function sortByName(a: string, b: string) {
  return (a).localeCompare(b);
}

/**
 * Sort the given notifications and return a data structure grouping them into
 * the various types, with description text for the ui.
 */
export function getNotificationsInfo(_notifications: PortalNotificationReference[], uiContent: any) {
  const { TaskText, TasksText, MessageText, MessagesText } = uiContent

  const notifications = compact(_notifications)

  const sortedNotifications: PortalNotificationReference[] = notifications.sort((a, b) => {
    const dateSort = sortByDate(a.CreatedDateTime, b.CreatedDateTime)
    if (dateSort) return dateSort
    return sortByName(a.NotificationTitle, b.NotificationTitle)
  })

  const tasks = sortedNotifications.filter(n => taskTypes.includes(n.Type))
  const wiringInstructionsTaskRef = sortedNotifications.find(n => n.Type === 'WiringInstructionsTask')
  const messages = sortedNotifications.filter(n => n.Type === 'Message')

  let [unreadMessages, readMessages] = partition(messages, ['ReadReceipt', false])
  let [incompleteTasks, completeTasks] = partition(tasks, ['IsComplete', false])

  const unreadMessagesCount = unreadMessages.length
  const incompleteTasksCount = incompleteTasks.length
  const messagesCountText = unreadMessagesCount ? `You have ${unreadMessages.length} new ${unreadMessages.length > 1 ? MessagesText : MessageText}!` : ''
  const tasksCountText = incompleteTasksCount ? `You have ${incompleteTasks.length} ${incompleteTasks.length > 1 ? TasksText : TaskText} to complete!` : ''

  const hasWiringInstructionsTask = !!wiringInstructionsTaskRef
  const wiringInstructionsTaskIsIncomplete = hasWiringInstructionsTask && !wiringInstructionsTaskRef.IsComplete
  const wiringInstructionsTaskText = wiringInstructionsTaskIsIncomplete
    ? "New instructions available!"
    : ""
  ;

  return {
    notifications,
    hasMessages: messages.length > 0,
    messagesCountText,
    messages,
    unreadMessages,
    readMessages,

    hasTasks: tasks.length > 0,
    tasksCountText,
    tasks,
    incompleteTasks,
    completeTasks,

    hasWiringInstructionsTask,
    wiringInstructionsTaskRef,
    wiringInstructionsTaskText,
  };
}


export type NotificationsInfo = ReturnType<typeof getNotificationsInfo>
