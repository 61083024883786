import { EmailOutlined, PeopleOutline, PhoneCallbackOutlined } from '@mui/icons-material';
import { Divider, IconButton, ListItem, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import { track } from '../../logic';
import { LogicContact } from '../../types';
import { emailUrl, phoneUrl } from '../../utils';
import { Section, SectionConfig } from '../shared';

export function ClosingContactsSection({ collapsible, contacts, sectionConfig }: {
	collapsible?: boolean;
	contacts: LogicContact[];
  sectionConfig: SectionConfig;
}) {
  return (
    <Section
      title={sectionConfig.name}
      icon={<PeopleOutline />}
      collapsible={collapsible}
    >
      <List>
        {contacts.map((contact, index) =>
          <ClosingContactsRow contact={contact} key={index} />,
        )}
      </List>
    </Section>
  )
}

function ClosingContactsRow({ contact }: {
	contact: any;
}) {

  const email = emailUrl(contact)
  const phone = phoneUrl(contact)

  function sendEvent(type: string) {
    track(`Click contact`, `${contact.Title} | ${type}`, {}, {
      contact_title: contact.Title,
      communication_type: type,
    });
  }

  return (
    <>
      <Divider />
      <ListItem>
        <ListItemText primary={contact.FullName} secondary={contact.Title} />

        <IconButton
          edge="end" aria-label="Send Email" sx={{ mr: 1 }} href={email || ''}
          target="_blank" rel="noreferrer" onClick={() => sendEvent('email')}
          disabled={!email}
        >
          <EmailOutlined />
        </IconButton>

        <IconButton
          edge="end" aria-label="Call" href={phone || ''} disabled={!phone}
          onClick={() => sendEvent('phone')}
        >
          <PhoneCallbackOutlined />
        </IconButton>
      </ListItem>
    </>
  )
}
